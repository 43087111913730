import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5ce3897a = () => interopDefault(import('../pages/daftar-implementasi-pesantren/index.vue' /* webpackChunkName: "pages/daftar-implementasi-pesantren/index" */))
const _5f152ce0 = () => interopDefault(import('../pages/login-history/index.vue' /* webpackChunkName: "pages/login-history/index" */))
const _34e6c7f9 = () => interopDefault(import('../pages/ppob/index.vue' /* webpackChunkName: "pages/ppob/index" */))
const _1e48c8d9 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _c39b50ce = () => interopDefault(import('../pages/read-more.vue' /* webpackChunkName: "pages/read-more" */))
const _efe3ed9e = () => interopDefault(import('../pages/santri/index.vue' /* webpackChunkName: "pages/santri/index" */))
const _376b7782 = () => interopDefault(import('../pages/admin/home.vue' /* webpackChunkName: "pages/admin/home" */))
const _0ef326a5 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _780f3417 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _556f1c2b = () => interopDefault(import('../pages/daftar-implementasi-pesantren/success.vue' /* webpackChunkName: "pages/daftar-implementasi-pesantren/success" */))
const _209c37d7 = () => interopDefault(import('../pages/host/midtrans/index.vue' /* webpackChunkName: "pages/host/midtrans/index" */))
const _0aa95397 = () => interopDefault(import('../pages/host/otto/index.vue' /* webpackChunkName: "pages/host/otto/index" */))
const _17974164 = () => interopDefault(import('../pages/host/pay.vue' /* webpackChunkName: "pages/host/pay" */))
const _a9768c44 = () => interopDefault(import('../pages/login-history/create.vue' /* webpackChunkName: "pages/login-history/create" */))
const _4cb304f2 = () => interopDefault(import('../pages/page/cek-status-booking/index.vue' /* webpackChunkName: "pages/page/cek-status-booking/index" */))
const _61dda9ee = () => interopDefault(import('../pages/page/kontak-kami.vue' /* webpackChunkName: "pages/page/kontak-kami" */))
const _7f66cd84 = () => interopDefault(import('../pages/page/peta-situs.vue' /* webpackChunkName: "pages/page/peta-situs" */))
const _5d76c544 = () => interopDefault(import('../pages/page/privacy-policy.vue' /* webpackChunkName: "pages/page/privacy-policy" */))
const _12182369 = () => interopDefault(import('../pages/page/tentang-kami.vue' /* webpackChunkName: "pages/page/tentang-kami" */))
const _0e0a64ed = () => interopDefault(import('../pages/santri/profil.vue' /* webpackChunkName: "pages/santri/profil" */))
const _115e54e8 = () => interopDefault(import('../pages/admin/core/akun-keuangan/index.vue' /* webpackChunkName: "pages/admin/core/akun-keuangan/index" */))
const _489e0211 = () => interopDefault(import('../pages/admin/core/buku-besar/index.vue' /* webpackChunkName: "pages/admin/core/buku-besar/index" */))
const _175d474a = () => interopDefault(import('../pages/admin/core/jurnal-khusus/index.vue' /* webpackChunkName: "pages/admin/core/jurnal-khusus/index" */))
const _3df9263e = () => interopDefault(import('../pages/admin/core/jurnal-penyesuaian/index.vue' /* webpackChunkName: "pages/admin/core/jurnal-penyesuaian/index" */))
const _dcd872be = () => interopDefault(import('../pages/admin/core/jurnal-umum/index.vue' /* webpackChunkName: "pages/admin/core/jurnal-umum/index" */))
const _f7060920 = () => interopDefault(import('../pages/admin/core/kertas-kerja/index.vue' /* webpackChunkName: "pages/admin/core/kertas-kerja/index" */))
const _3bcfde58 = () => interopDefault(import('../pages/admin/core/laba-rugi/index.vue' /* webpackChunkName: "pages/admin/core/laba-rugi/index" */))
const _ce8c1364 = () => interopDefault(import('../pages/admin/core/laporan-keuangan/index.vue' /* webpackChunkName: "pages/admin/core/laporan-keuangan/index" */))
const _e9d40438 = () => interopDefault(import('../pages/admin/core/neraca/index.vue' /* webpackChunkName: "pages/admin/core/neraca/index" */))
const _1d5074d0 = () => interopDefault(import('../pages/admin/customer-service/blast-wa/index.vue' /* webpackChunkName: "pages/admin/customer-service/blast-wa/index" */))
const _c9fc87f6 = () => interopDefault(import('../pages/admin/customer-service/nasabah/index.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/index" */))
const _c0348cd8 = () => interopDefault(import('../pages/admin/customer-service/registrasi/index.vue' /* webpackChunkName: "pages/admin/customer-service/registrasi/index" */))
const _16a5b670 = () => interopDefault(import('../pages/admin/customer-service/rekening-koran/index.vue' /* webpackChunkName: "pages/admin/customer-service/rekening-koran/index" */))
const _4d301aa0 = () => interopDefault(import('../pages/admin/customer-service/rekening-wali/index.vue' /* webpackChunkName: "pages/admin/customer-service/rekening-wali/index" */))
const _5ceaf746 = () => interopDefault(import('../pages/admin/customer-service/riwayat-transfer/index.vue' /* webpackChunkName: "pages/admin/customer-service/riwayat-transfer/index" */))
const _457fdf2e = () => interopDefault(import('../pages/admin/customer-service/tagihan-spp/index.vue' /* webpackChunkName: "pages/admin/customer-service/tagihan-spp/index" */))
const _01fadd05 = () => interopDefault(import('../pages/admin/customer-service/transaksi/index.vue' /* webpackChunkName: "pages/admin/customer-service/transaksi/index" */))
const _12e8d9a0 = () => interopDefault(import('../pages/admin/customer-service/wali/index.vue' /* webpackChunkName: "pages/admin/customer-service/wali/index" */))
const _4df4dbf9 = () => interopDefault(import('../pages/admin/master/bank/index.vue' /* webpackChunkName: "pages/admin/master/bank/index" */))
const _8c88af0e = () => interopDefault(import('../pages/admin/master/bcn-bank/index.vue' /* webpackChunkName: "pages/admin/master/bcn-bank/index" */))
const _58148565 = () => interopDefault(import('../pages/admin/master/document-type/index.vue' /* webpackChunkName: "pages/admin/master/document-type/index" */))
const _5c9d0186 = () => interopDefault(import('../pages/admin/master/jenis-kas/index.vue' /* webpackChunkName: "pages/admin/master/jenis-kas/index" */))
const _2f9c3ddb = () => interopDefault(import('../pages/admin/master/jenis-pinjaman/index.vue' /* webpackChunkName: "pages/admin/master/jenis-pinjaman/index" */))
const _201582ce = () => interopDefault(import('../pages/admin/master/jenis-rekening/index.vue' /* webpackChunkName: "pages/admin/master/jenis-rekening/index" */))
const _b530eb40 = () => interopDefault(import('../pages/admin/master/jenis-simpanan/index.vue' /* webpackChunkName: "pages/admin/master/jenis-simpanan/index" */))
const _15d28934 = () => interopDefault(import('../pages/admin/master/lembaga/index.vue' /* webpackChunkName: "pages/admin/master/lembaga/index" */))
const _ea6a6060 = () => interopDefault(import('../pages/admin/master/news/index.vue' /* webpackChunkName: "pages/admin/master/news/index" */))
const _3c67342a = () => interopDefault(import('../pages/admin/master/produk/index.vue' /* webpackChunkName: "pages/admin/master/produk/index" */))
const _48c2c534 = () => interopDefault(import('../pages/admin/master/setting-institution/index.vue' /* webpackChunkName: "pages/admin/master/setting-institution/index" */))
const _599b4174 = () => interopDefault(import('../pages/admin/master/transaction-type/index.vue' /* webpackChunkName: "pages/admin/master/transaction-type/index" */))
const _9ceec3d2 = () => interopDefault(import('../pages/admin/pengaturan/management-user/index.vue' /* webpackChunkName: "pages/admin/pengaturan/management-user/index" */))
const _232c18ba = () => interopDefault(import('../pages/admin/pengaturan/permission/index.vue' /* webpackChunkName: "pages/admin/pengaturan/permission/index" */))
const _8060cf6c = () => interopDefault(import('../pages/admin/pengaturan/role/index.vue' /* webpackChunkName: "pages/admin/pengaturan/role/index" */))
const _7272839b = () => interopDefault(import('../pages/admin/pengaturan/role-has-permission/index.vue' /* webpackChunkName: "pages/admin/pengaturan/role-has-permission/index" */))
const _017664f7 = () => interopDefault(import('../pages/admin/transaksi/monitoring-admin/index.vue' /* webpackChunkName: "pages/admin/transaksi/monitoring-admin/index" */))
const _71877622 = () => interopDefault(import('../pages/admin/transaksi/penarikan/index.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan/index" */))
const _a451e6fe = () => interopDefault(import('../pages/admin/transaksi/penarikan-otomatis/index.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan-otomatis/index" */))
const _be707dba = () => interopDefault(import('../pages/admin/transaksi/setoran-tunai/index.vue' /* webpackChunkName: "pages/admin/transaksi/setoran-tunai/index" */))
const _67e1eca6 = () => interopDefault(import('../pages/host/flip/accept.vue' /* webpackChunkName: "pages/host/flip/accept" */))
const _41129c89 = () => interopDefault(import('../pages/host/flip/transfer.vue' /* webpackChunkName: "pages/host/flip/transfer" */))
const _1a7e26b2 = () => interopDefault(import('../pages/admin/core/akun-keuangan/create.vue' /* webpackChunkName: "pages/admin/core/akun-keuangan/create" */))
const _6ecd4f86 = () => interopDefault(import('../pages/admin/core/jurnal-umum/create.vue' /* webpackChunkName: "pages/admin/core/jurnal-umum/create" */))
const _6f60f3a7 = () => interopDefault(import('../pages/admin/customer-service/blast-wa/template/index.vue' /* webpackChunkName: "pages/admin/customer-service/blast-wa/template/index" */))
const _262be14e = () => interopDefault(import('../pages/admin/customer-service/nasabah/create.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/create" */))
const _b07533de = () => interopDefault(import('../pages/admin/customer-service/nasabah/export.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/export" */))
const _348f23a1 = () => interopDefault(import('../pages/admin/customer-service/nasabah/rekening/index.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/rekening/index" */))
const _e65f1c44 = () => interopDefault(import('../pages/admin/customer-service/nasabah/upload.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/upload" */))
const _f6f478ac = () => interopDefault(import('../pages/admin/customer-service/registrasi/create.vue' /* webpackChunkName: "pages/admin/customer-service/registrasi/create" */))
const _71e6a51e = () => interopDefault(import('../pages/admin/customer-service/wali/create.vue' /* webpackChunkName: "pages/admin/customer-service/wali/create" */))
const _2a5fb734 = () => interopDefault(import('../pages/admin/customer-service/wali/rekening/index.vue' /* webpackChunkName: "pages/admin/customer-service/wali/rekening/index" */))
const _cf4a2436 = () => interopDefault(import('../pages/admin/master/bank/create.vue' /* webpackChunkName: "pages/admin/master/bank/create" */))
const _b5249d36 = () => interopDefault(import('../pages/admin/master/bcn-bank/create.vue' /* webpackChunkName: "pages/admin/master/bcn-bank/create" */))
const _5b9f1c0e = () => interopDefault(import('../pages/admin/master/document-type/create.vue' /* webpackChunkName: "pages/admin/master/document-type/create" */))
const _5eb779f8 = () => interopDefault(import('../pages/admin/master/jenis-kas/create.vue' /* webpackChunkName: "pages/admin/master/jenis-kas/create" */))
const _28c06f7a = () => interopDefault(import('../pages/admin/master/jenis-pinjaman/create.vue' /* webpackChunkName: "pages/admin/master/jenis-pinjaman/create" */))
const _0a4f21b0 = () => interopDefault(import('../pages/admin/master/jenis-rekening/create.vue' /* webpackChunkName: "pages/admin/master/jenis-rekening/create" */))
const _2f3e0c5e = () => interopDefault(import('../pages/admin/master/jenis-simpanan/create.vue' /* webpackChunkName: "pages/admin/master/jenis-simpanan/create" */))
const _269200cc = () => interopDefault(import('../pages/admin/master/lembaga/bcn-code/index.vue' /* webpackChunkName: "pages/admin/master/lembaga/bcn-code/index" */))
const _551607d0 = () => interopDefault(import('../pages/admin/master/lembaga/create.vue' /* webpackChunkName: "pages/admin/master/lembaga/create" */))
const _13791624 = () => interopDefault(import('../pages/admin/master/news/create.vue' /* webpackChunkName: "pages/admin/master/news/create" */))
const _78339bd4 = () => interopDefault(import('../pages/admin/master/produk/create.vue' /* webpackChunkName: "pages/admin/master/produk/create" */))
const _8a645790 = () => interopDefault(import('../pages/admin/master/transaction-type/create.vue' /* webpackChunkName: "pages/admin/master/transaction-type/create" */))
const _b18120f2 = () => interopDefault(import('../pages/admin/pengaturan/management-user/create.vue' /* webpackChunkName: "pages/admin/pengaturan/management-user/create" */))
const _0688cb7b = () => interopDefault(import('../pages/admin/pengaturan/permission/create.vue' /* webpackChunkName: "pages/admin/pengaturan/permission/create" */))
const _61d7bbb4 = () => interopDefault(import('../pages/admin/pengaturan/role/create.vue' /* webpackChunkName: "pages/admin/pengaturan/role/create" */))
const _34bece5d = () => interopDefault(import('../pages/admin/transaksi/penarikan-otomatis/create.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan-otomatis/create" */))
const _31c8ce48 = () => interopDefault(import('../pages/admin/transaksi/penarikan/create.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan/create" */))
const _c036a40a = () => interopDefault(import('../pages/admin/transaksi/setoran-tunai/create.vue' /* webpackChunkName: "pages/admin/transaksi/setoran-tunai/create" */))
const _b71c6812 = () => interopDefault(import('../pages/admin/customer-service/blast-wa/template/create.vue' /* webpackChunkName: "pages/admin/customer-service/blast-wa/template/create" */))
const _32424282 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6e15b3cf = () => interopDefault(import('../pages/admin/customer-service/blast-wa/template/_id.vue' /* webpackChunkName: "pages/admin/customer-service/blast-wa/template/_id" */))
const _07eecaf4 = () => interopDefault(import('../pages/admin/core/akun-keuangan/_id.vue' /* webpackChunkName: "pages/admin/core/akun-keuangan/_id" */))
const _2168fbad = () => interopDefault(import('../pages/admin/customer-service/nasabah/_id.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/_id" */))
const _6820c408 = () => interopDefault(import('../pages/admin/customer-service/wali/_id.vue' /* webpackChunkName: "pages/admin/customer-service/wali/_id" */))
const _ba68eebe = () => interopDefault(import('../pages/admin/master/bank/_id.vue' /* webpackChunkName: "pages/admin/master/bank/_id" */))
const _5a4915be = () => interopDefault(import('../pages/admin/master/bcn-bank/_id.vue' /* webpackChunkName: "pages/admin/master/bcn-bank/_id" */))
const _055e9d0d = () => interopDefault(import('../pages/admin/master/document-type/_id.vue' /* webpackChunkName: "pages/admin/master/document-type/_id" */))
const _bf2ca524 = () => interopDefault(import('../pages/admin/master/jenis-kas/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-kas/_id" */))
const _3945fb03 = () => interopDefault(import('../pages/admin/master/jenis-pinjaman/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-pinjaman/_id" */))
const _9dbc3e94 = () => interopDefault(import('../pages/admin/master/jenis-rekening/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-rekening/_id" */))
const _2814a4c8 = () => interopDefault(import('../pages/admin/master/jenis-simpanan/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-simpanan/_id" */))
const _15c85f4e = () => interopDefault(import('../pages/admin/master/lembaga/_id.vue' /* webpackChunkName: "pages/admin/master/lembaga/_id" */))
const _e92af390 = () => interopDefault(import('../pages/admin/master/news/_id.vue' /* webpackChunkName: "pages/admin/master/news/_id" */))
const _54af8ddc = () => interopDefault(import('../pages/admin/master/produk/_id.vue' /* webpackChunkName: "pages/admin/master/produk/_id" */))
const _9e2ef6c8 = () => interopDefault(import('../pages/admin/master/setting-institution/_id.vue' /* webpackChunkName: "pages/admin/master/setting-institution/_id" */))
const _778909a4 = () => interopDefault(import('../pages/admin/master/transaction-type/_id.vue' /* webpackChunkName: "pages/admin/master/transaction-type/_id" */))
const _23f58b82 = () => interopDefault(import('../pages/admin/pengaturan/management-user/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/management-user/_id" */))
const _2bd2fa6a = () => interopDefault(import('../pages/admin/pengaturan/permission/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/permission/_id" */))
const _d71a9e7a = () => interopDefault(import('../pages/admin/pengaturan/role-has-permission/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/role-has-permission/_id" */))
const _abc9b59c = () => interopDefault(import('../pages/admin/pengaturan/role/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/role/_id" */))
const _727cc84c = () => interopDefault(import('../pages/page/cek-status-booking/_id.vue' /* webpackChunkName: "pages/page/cek-status-booking/_id" */))
const _f304aec4 = () => interopDefault(import('../pages/auth/_registerBak.vue' /* webpackChunkName: "pages/auth/_registerBak" */))
const _7dc8e748 = () => interopDefault(import('../pages/login-history/_id.vue' /* webpackChunkName: "pages/login-history/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/daftar-implementasi-pesantren",
    component: _5ce3897a,
    name: "daftar-implementasi-pesantren"
  }, {
    path: "/login-history",
    component: _5f152ce0,
    name: "login-history"
  }, {
    path: "/ppob",
    component: _34e6c7f9,
    name: "ppob"
  }, {
    path: "/profile",
    component: _1e48c8d9,
    name: "profile"
  }, {
    path: "/read-more",
    component: _c39b50ce,
    name: "read-more"
  }, {
    path: "/santri",
    component: _efe3ed9e,
    name: "santri"
  }, {
    path: "/admin/home",
    component: _376b7782,
    name: "admin-home"
  }, {
    path: "/auth/login",
    component: _0ef326a5,
    name: "auth-login"
  }, {
    path: "/auth/register",
    component: _780f3417,
    name: "auth-register"
  }, {
    path: "/daftar-implementasi-pesantren/success",
    component: _556f1c2b,
    name: "daftar-implementasi-pesantren-success"
  }, {
    path: "/host/midtrans",
    component: _209c37d7,
    name: "host-midtrans"
  }, {
    path: "/host/otto",
    component: _0aa95397,
    name: "host-otto"
  }, {
    path: "/host/pay",
    component: _17974164,
    name: "host-pay"
  }, {
    path: "/login-history/create",
    component: _a9768c44,
    name: "login-history-create"
  }, {
    path: "/page/cek-status-booking",
    component: _4cb304f2,
    name: "page-cek-status-booking"
  }, {
    path: "/page/kontak-kami",
    component: _61dda9ee,
    name: "page-kontak-kami"
  }, {
    path: "/page/peta-situs",
    component: _7f66cd84,
    name: "page-peta-situs"
  }, {
    path: "/page/privacy-policy",
    component: _5d76c544,
    name: "page-privacy-policy"
  }, {
    path: "/page/tentang-kami",
    component: _12182369,
    name: "page-tentang-kami"
  }, {
    path: "/santri/profil",
    component: _0e0a64ed,
    name: "santri-profil"
  }, {
    path: "/admin/core/akun-keuangan",
    component: _115e54e8,
    name: "admin-core-akun-keuangan"
  }, {
    path: "/admin/core/buku-besar",
    component: _489e0211,
    name: "admin-core-buku-besar"
  }, {
    path: "/admin/core/jurnal-khusus",
    component: _175d474a,
    name: "admin-core-jurnal-khusus"
  }, {
    path: "/admin/core/jurnal-penyesuaian",
    component: _3df9263e,
    name: "admin-core-jurnal-penyesuaian"
  }, {
    path: "/admin/core/jurnal-umum",
    component: _dcd872be,
    name: "admin-core-jurnal-umum"
  }, {
    path: "/admin/core/kertas-kerja",
    component: _f7060920,
    name: "admin-core-kertas-kerja"
  }, {
    path: "/admin/core/laba-rugi",
    component: _3bcfde58,
    name: "admin-core-laba-rugi"
  }, {
    path: "/admin/core/laporan-keuangan",
    component: _ce8c1364,
    name: "admin-core-laporan-keuangan"
  }, {
    path: "/admin/core/neraca",
    component: _e9d40438,
    name: "admin-core-neraca"
  }, {
    path: "/admin/customer-service/blast-wa",
    component: _1d5074d0,
    name: "admin-customer-service-blast-wa"
  }, {
    path: "/admin/customer-service/nasabah",
    component: _c9fc87f6,
    name: "admin-customer-service-nasabah"
  }, {
    path: "/admin/customer-service/registrasi",
    component: _c0348cd8,
    name: "admin-customer-service-registrasi"
  }, {
    path: "/admin/customer-service/rekening-koran",
    component: _16a5b670,
    name: "admin-customer-service-rekening-koran"
  }, {
    path: "/admin/customer-service/rekening-wali",
    component: _4d301aa0,
    name: "admin-customer-service-rekening-wali"
  }, {
    path: "/admin/customer-service/riwayat-transfer",
    component: _5ceaf746,
    name: "admin-customer-service-riwayat-transfer"
  }, {
    path: "/admin/customer-service/tagihan-spp",
    component: _457fdf2e,
    name: "admin-customer-service-tagihan-spp"
  }, {
    path: "/admin/customer-service/transaksi",
    component: _01fadd05,
    name: "admin-customer-service-transaksi"
  }, {
    path: "/admin/customer-service/wali",
    component: _12e8d9a0,
    name: "admin-customer-service-wali"
  }, {
    path: "/admin/master/bank",
    component: _4df4dbf9,
    name: "admin-master-bank"
  }, {
    path: "/admin/master/bcn-bank",
    component: _8c88af0e,
    name: "admin-master-bcn-bank"
  }, {
    path: "/admin/master/document-type",
    component: _58148565,
    name: "admin-master-document-type"
  }, {
    path: "/admin/master/jenis-kas",
    component: _5c9d0186,
    name: "admin-master-jenis-kas"
  }, {
    path: "/admin/master/jenis-pinjaman",
    component: _2f9c3ddb,
    name: "admin-master-jenis-pinjaman"
  }, {
    path: "/admin/master/jenis-rekening",
    component: _201582ce,
    name: "admin-master-jenis-rekening"
  }, {
    path: "/admin/master/jenis-simpanan",
    component: _b530eb40,
    name: "admin-master-jenis-simpanan"
  }, {
    path: "/admin/master/lembaga",
    component: _15d28934,
    name: "admin-master-lembaga"
  }, {
    path: "/admin/master/news",
    component: _ea6a6060,
    name: "admin-master-news"
  }, {
    path: "/admin/master/produk",
    component: _3c67342a,
    name: "admin-master-produk"
  }, {
    path: "/admin/master/setting-institution",
    component: _48c2c534,
    name: "admin-master-setting-institution"
  }, {
    path: "/admin/master/transaction-type",
    component: _599b4174,
    name: "admin-master-transaction-type"
  }, {
    path: "/admin/pengaturan/management-user",
    component: _9ceec3d2,
    name: "admin-pengaturan-management-user"
  }, {
    path: "/admin/pengaturan/permission",
    component: _232c18ba,
    name: "admin-pengaturan-permission"
  }, {
    path: "/admin/pengaturan/role",
    component: _8060cf6c,
    name: "admin-pengaturan-role"
  }, {
    path: "/admin/pengaturan/role-has-permission",
    component: _7272839b,
    name: "admin-pengaturan-role-has-permission"
  }, {
    path: "/admin/transaksi/monitoring-admin",
    component: _017664f7,
    name: "admin-transaksi-monitoring-admin"
  }, {
    path: "/admin/transaksi/penarikan",
    component: _71877622,
    name: "admin-transaksi-penarikan"
  }, {
    path: "/admin/transaksi/penarikan-otomatis",
    component: _a451e6fe,
    name: "admin-transaksi-penarikan-otomatis"
  }, {
    path: "/admin/transaksi/setoran-tunai",
    component: _be707dba,
    name: "admin-transaksi-setoran-tunai"
  }, {
    path: "/host/flip/accept",
    component: _67e1eca6,
    name: "host-flip-accept"
  }, {
    path: "/host/flip/transfer",
    component: _41129c89,
    name: "host-flip-transfer"
  }, {
    path: "/admin/core/akun-keuangan/create",
    component: _1a7e26b2,
    name: "admin-core-akun-keuangan-create"
  }, {
    path: "/admin/core/jurnal-umum/create",
    component: _6ecd4f86,
    name: "admin-core-jurnal-umum-create"
  }, {
    path: "/admin/customer-service/blast-wa/template",
    component: _6f60f3a7,
    name: "admin-customer-service-blast-wa-template"
  }, {
    path: "/admin/customer-service/nasabah/create",
    component: _262be14e,
    name: "admin-customer-service-nasabah-create"
  }, {
    path: "/admin/customer-service/nasabah/export",
    component: _b07533de,
    name: "admin-customer-service-nasabah-export"
  }, {
    path: "/admin/customer-service/nasabah/rekening",
    component: _348f23a1,
    name: "admin-customer-service-nasabah-rekening"
  }, {
    path: "/admin/customer-service/nasabah/upload",
    component: _e65f1c44,
    name: "admin-customer-service-nasabah-upload"
  }, {
    path: "/admin/customer-service/registrasi/create",
    component: _f6f478ac,
    name: "admin-customer-service-registrasi-create"
  }, {
    path: "/admin/customer-service/wali/create",
    component: _71e6a51e,
    name: "admin-customer-service-wali-create"
  }, {
    path: "/admin/customer-service/wali/rekening",
    component: _2a5fb734,
    name: "admin-customer-service-wali-rekening"
  }, {
    path: "/admin/master/bank/create",
    component: _cf4a2436,
    name: "admin-master-bank-create"
  }, {
    path: "/admin/master/bcn-bank/create",
    component: _b5249d36,
    name: "admin-master-bcn-bank-create"
  }, {
    path: "/admin/master/document-type/create",
    component: _5b9f1c0e,
    name: "admin-master-document-type-create"
  }, {
    path: "/admin/master/jenis-kas/create",
    component: _5eb779f8,
    name: "admin-master-jenis-kas-create"
  }, {
    path: "/admin/master/jenis-pinjaman/create",
    component: _28c06f7a,
    name: "admin-master-jenis-pinjaman-create"
  }, {
    path: "/admin/master/jenis-rekening/create",
    component: _0a4f21b0,
    name: "admin-master-jenis-rekening-create"
  }, {
    path: "/admin/master/jenis-simpanan/create",
    component: _2f3e0c5e,
    name: "admin-master-jenis-simpanan-create"
  }, {
    path: "/admin/master/lembaga/bcn-code",
    component: _269200cc,
    name: "admin-master-lembaga-bcn-code"
  }, {
    path: "/admin/master/lembaga/create",
    component: _551607d0,
    name: "admin-master-lembaga-create"
  }, {
    path: "/admin/master/news/create",
    component: _13791624,
    name: "admin-master-news-create"
  }, {
    path: "/admin/master/produk/create",
    component: _78339bd4,
    name: "admin-master-produk-create"
  }, {
    path: "/admin/master/transaction-type/create",
    component: _8a645790,
    name: "admin-master-transaction-type-create"
  }, {
    path: "/admin/pengaturan/management-user/create",
    component: _b18120f2,
    name: "admin-pengaturan-management-user-create"
  }, {
    path: "/admin/pengaturan/permission/create",
    component: _0688cb7b,
    name: "admin-pengaturan-permission-create"
  }, {
    path: "/admin/pengaturan/role/create",
    component: _61d7bbb4,
    name: "admin-pengaturan-role-create"
  }, {
    path: "/admin/transaksi/penarikan-otomatis/create",
    component: _34bece5d,
    name: "admin-transaksi-penarikan-otomatis-create"
  }, {
    path: "/admin/transaksi/penarikan/create",
    component: _31c8ce48,
    name: "admin-transaksi-penarikan-create"
  }, {
    path: "/admin/transaksi/setoran-tunai/create",
    component: _c036a40a,
    name: "admin-transaksi-setoran-tunai-create"
  }, {
    path: "/admin/customer-service/blast-wa/template/create",
    component: _b71c6812,
    name: "admin-customer-service-blast-wa-template-create"
  }, {
    path: "/",
    component: _32424282,
    name: "index"
  }, {
    path: "/admin/customer-service/blast-wa/template/:id?",
    component: _6e15b3cf,
    name: "admin-customer-service-blast-wa-template-id"
  }, {
    path: "/admin/core/akun-keuangan/:id?",
    component: _07eecaf4,
    name: "admin-core-akun-keuangan-id"
  }, {
    path: "/admin/customer-service/nasabah/:id?",
    component: _2168fbad,
    name: "admin-customer-service-nasabah-id"
  }, {
    path: "/admin/customer-service/wali/:id?",
    component: _6820c408,
    name: "admin-customer-service-wali-id"
  }, {
    path: "/admin/master/bank/:id",
    component: _ba68eebe,
    name: "admin-master-bank-id"
  }, {
    path: "/admin/master/bcn-bank/:id?",
    component: _5a4915be,
    name: "admin-master-bcn-bank-id"
  }, {
    path: "/admin/master/document-type/:id?",
    component: _055e9d0d,
    name: "admin-master-document-type-id"
  }, {
    path: "/admin/master/jenis-kas/:id?",
    component: _bf2ca524,
    name: "admin-master-jenis-kas-id"
  }, {
    path: "/admin/master/jenis-pinjaman/:id?",
    component: _3945fb03,
    name: "admin-master-jenis-pinjaman-id"
  }, {
    path: "/admin/master/jenis-rekening/:id?",
    component: _9dbc3e94,
    name: "admin-master-jenis-rekening-id"
  }, {
    path: "/admin/master/jenis-simpanan/:id?",
    component: _2814a4c8,
    name: "admin-master-jenis-simpanan-id"
  }, {
    path: "/admin/master/lembaga/:id",
    component: _15c85f4e,
    name: "admin-master-lembaga-id"
  }, {
    path: "/admin/master/news/:id",
    component: _e92af390,
    name: "admin-master-news-id"
  }, {
    path: "/admin/master/produk/:id",
    component: _54af8ddc,
    name: "admin-master-produk-id"
  }, {
    path: "/admin/master/setting-institution/:id?",
    component: _9e2ef6c8,
    name: "admin-master-setting-institution-id"
  }, {
    path: "/admin/master/transaction-type/:id?",
    component: _778909a4,
    name: "admin-master-transaction-type-id"
  }, {
    path: "/admin/pengaturan/management-user/:id?",
    component: _23f58b82,
    name: "admin-pengaturan-management-user-id"
  }, {
    path: "/admin/pengaturan/permission/:id",
    component: _2bd2fa6a,
    name: "admin-pengaturan-permission-id"
  }, {
    path: "/admin/pengaturan/role-has-permission/:id",
    component: _d71a9e7a,
    name: "admin-pengaturan-role-has-permission-id"
  }, {
    path: "/admin/pengaturan/role/:id",
    component: _abc9b59c,
    name: "admin-pengaturan-role-id"
  }, {
    path: "/page/cek-status-booking/:id?",
    component: _727cc84c,
    name: "page-cek-status-booking-id"
  }, {
    path: "/auth/:registerBak?",
    component: _f304aec4,
    name: "auth-registerBak"
  }, {
    path: "/login-history/:id?",
    component: _7dc8e748,
    name: "login-history-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
